<template>
  <div class="topcard-item-row "
    :style="{ backgroundColor: themeConfig.bigCardConfig[0].cardBackground }">
    <div class="topcard-item-theme type-1" @click="handleClick">
      <div class="topcard-item-sources">
        <img v-lazy="data.headImg?.[0]" alt="">
      </div>
      <div class="topcard-content-row"
        :style="{ backgroundColor: themeConfig.bigCardConfig[0].cardContentBackground }">
        <h3 class="topcard-item-title">
          {{ data.title }}
        </h3>
        <div class="topcard-item-date">
          <img src="@/assets/news/dates.png" alt="">
          {{ $global.formatDate($global.dateStringToTimestamp(data.published), 'MM-dd-yyyy') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '@/css/module/bigcard/theme_banner_pc.scss';

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    themeConfig: {
      type: Object,
      required: true,
    }
  },
  methods: {
    handleClick () {
      this.$emit('customClick');
    }
  }
};
</script>
